<template>
  <VAppBar
    id="default-app-bar"
    class=""
    elevation="0"
    absolute
    height="auto"
    flat
    color="transparent"
    dark
    tile
  >
    <v-container
      fluid
      class="py-4"
    >
      <v-row justify="center">
        <v-col
          cols="12"
          lg="11"
          xl="10"
        >
          <v-row
            justify="center"
            align="center"
          >
            <v-col
              cols="6"
              md="2"
              class="order-1 order-md-1 d-flex justify-start align-center"
            >
              <v-btn
                plain
                text
                class="btn-home"
                :width="$vuetify.breakpoint.mdAndUp ?'209px':'163px'"
                @click="_toAnchor('reel')"
              >
                <v-img
                  position="left center"
                  contain
                  :width="$vuetify.breakpoint.mdAndUp ?'209px':'163px'"
                  :src="`${app.logoUrl}`"
                />
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="8"
              class="order-3 order-md-2 d-flex justify-center align-center pt-0 pt-md-2"
            >
              <v-row
                justify="center"
                align="center"
              >
                <v-col
                  v-for="(menuItem,index) in menuItems"
                  :key="index"
                  cols="auto"
                  class="py-0"
                >
                  <v-btn
                    text
                    class="text-decoration-none white--text no-background-hover text-subtitle-2 font-weight-bold"
                    @click="menuItem.to && _toAnchor(menuItem.to)"
                  >
                    {{ menuItem[`text${lang==='es'?'':'_en'}`] }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="6"
              md="2"
              class="d-flex justify-end align-center order-2 order-md-3"
            >
              <v-btn
                icon
                class="btn-lang no-background-hover"
                small
                @click="changeLang"
              >
                <v-img
                  position="center center"
                  contain
                  eager
                  height="21px"
                  :src="`${lang==='es'?app.lang.logoEn:app.lang.logoEs}`"
                  max-width="110px"
                  class="flag-icon"
                />
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </VAppBar>
</template>

<script>
  import { setToken } from '@/util/auth'
  import { get, sync } from 'vuex-pathify'
  import { capitalize } from '@/util/helpers'
  import anchors from '@/mixins/anchors'

  export default {
    name: 'DefaultBar',
    mixins: [anchors],
    props: {
      isHome: {
        type: Boolean,
        default: false,
      },
      item: {
        type: [Object],
        default: function () {
          return {}
        },
      },
      eventsBus: {
        type: Object,
        default: function () {
          return {}
        },
      },
    },
    data: () => ({
    }),
    computed: {
      app: get('app'),
      lang: sync('app/langDefault'),
      menuItems: sync('app/blocks.nav.items'),
    },
    methods: {
      changeLang () {
        this.lang = this.lang === 'es' ? 'en' : 'es'
        setToken(this.lang, 'six_studio_lang')
      },
      _toAnchor (to) {
        const _to = capitalize(to)
        if (!this.isHome) {
          if (_to !== 'Contacto') {
            this.eventsBus.$emit('closeItem')
            this.toAnchor({ id: `call${_to}Anchor` })
          } else {
            this.$emit('clickContactBtn')
          }
        } else {
          this.toAnchor({ id: `call${_to}Anchor` })
        }
      },
    },
  }
</script>
<style  lang="scss">
  #default-app-bar{
    background: rgba(37, 36, 34, 0.25)!important;
    .btn-home{
      .v-btn__content{
        opacity: 1;
      }

    }
    z-index:2;
    .v-toolbar__content{
        padding:0;
        display: block;
    }
    .btn-lang{
        background-color: white!important;
    }
    .flag-icon{
      margin-left:-1px;
    }
  }
</style>
