<template>
  <svg
    :width="width"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM21.143 16.969H17.429V30H21.429V23.687C21.429 21.572 22.214 20.227 24.103 20.227C25.465 20.227 26 21.475 26 23.688V30H30V22.803C30 18.917 29.062 16.799 25.111 16.799C23.05 16.799 21.678 17.755 21.143 18.819V16.969ZM14.57 16.799V29.83H10.57V16.799H14.57ZM14.9474 13.5266C15.0767 13.2172 15.1431 12.8853 15.143 12.55C15.143 11.873 14.872 11.225 14.39 10.747C13.907 10.269 13.253 10 12.571 10C11.8902 9.9997 11.2369 10.2682 10.753 10.747C10.27 11.225 10 11.873 10 12.55C10.0006 12.8852 10.0673 13.217 10.1963 13.5264C10.3254 13.8358 10.5142 14.1167 10.752 14.353C11.2363 14.8312 11.8894 15.0995 12.57 15.1C13.2518 15.1017 13.9064 14.8326 14.39 14.352C14.6287 14.1165 14.8181 13.8359 14.9474 13.5266Z"
      :fill="fill"
    />
  </svg>
</template>

<script>

  export default {
    name: 'LogoLinkedin',
    props: {
      width: {
        type: Number,
        default: 40,
      },
      fill: {
        type: String,
        default: '#252422',
      },
    },
  }
</script>

<style>

</style>
