<template>
  <v-main
    ref="overlay__item_inner"
    class="ma-0 overlay__item_inner"
    :style="{background: $vuetify.theme.themes[$vuetify.theme.dark? 'dark' : 'light'].background}"
  >
    <default-bar
      ref="app_bar"
      :item="item"
      :events-bus="eventsBus"
      @clickContactBtn="$emit('toEvent','bottom')"
    />
    <hero
      v-if="item.id ===proyectOpen"
      :item="item"
    />
    <v-container
      class="mt-10"
      fluid
    >
      <v-row
        justify="center"
      >
        <v-col
          cols="12"
          lg="11"
          xl="10"
          class="py-0"
        >
          <v-row
            justify="center"
          >
            <v-col
              cols="12"
              sm="10"
              md="11"
              lg="10"
              class="pt-0"
            >
              <button
                ref="work_close"
                class="overlay__close d-flex align-center link--text text-subtitle-1 font-weight-regular"
                :data-id="item.id"
                @click="scrollPosition=300"
              >
                <v-img
                  class=""
                  contain
                  position="center center"
                  src="/images/2023/home/chevron_left.svg"
                  alt="arrow back"
                  width="17px"
                />

                Volver
              </button>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          lg="11"
          xl="10"
        >
          <info
            v-if="item.id ===proyectOpen"
            :item="item"
          />
        </v-col>
      </v-row>
    </v-container>
    <default-footer
      :item="item"
      @clickTopBtn="$emit('toEvent','top')"
    />
  </v-main>
</template>
<script>
// Styles
  import { get, sync } from 'vuex-pathify'
  import DefaultBar from '@/layouts/default/AppBar'
  import Hero from './components/Hero.vue'
  import Info from './components/Info.vue'
  import DefaultFooter from '@/layouts/default/Footer'

  export default {
    name: 'App',
    components: {
      DefaultBar,
      Hero,
      Info,
      DefaultFooter,
    },
    props: {
      eventsBus: {
        type: Object,
        default: function () {
          return {}
        },
      },
      item: {
        type: [Object],
        default: function () {
          return {}
        },
      },
    },

    data: () => ({

    }),
    computed: {
      lang: get('app/langDefault'),
      proyectOpen: get('app/proyectOpen'),
      ...sync('app', ['scrollPosition']),
    },
    mounted () {
      if (this.eventsBus?.$on) {
        this.eventsBus.$on('closeItem', this.closeItem)
      }
    },

    methods: {
      closeItem () {
        if (this.$refs.work_close?.classList?.contains('is-open')) {
          this.scrollPosition = 300
          this.$refs.work_close.click()
        }
      },

    },

  }
</script>
