<template>
  <VApp
    id="myApp"
    v-scroll="updateElementScroll"
    :style="{background: $vuetify.theme.themes[$vuetify.theme.dark? 'dark' : 'light'].background}"
  >
    <div
      ref="overlay"
      class="overlay ma-0 scrollsito"
    >
      <div
        class="overlay__reveal"
        :style="`--bg-color: ${$vuetify.theme.themes[$vuetify.theme.light? 'dark' : 'light'].background};--bg-transition-color: ${$vuetify.theme.themes[$vuetify.theme.dark? 'light' : 'dark'].background};`"
      />

      <div
        v-for="(row,index) in items"
        :id="`proyecto-${row.id}`"
        :key="`proyectos_${index}`"
        class="overlay__item"
      >
        <work
          ref="work"
          :item="row"
          :events-bus="eventsBus"
          @toEvent="handleClickTo"
        />
      </div>
    </div>

    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
    <VSnackbar
      v-model="alert"
      right
      :timeout="alertTxt.timeout"
      top
    >
      <VAlert
        :type="alertTxt.type"
        v-html="alertTxt.txt"
      />
    </VSnackbar>
  </VApp>
</template>

<script>
// Styles
  import { sortby, filter } from '@/util/helpers'
  import { get, sync } from 'vuex-pathify'
  import Work from '@/views/work/Work.vue'
  import Vue from 'vue'

  export default {
    name: 'App',
    components: {
      Work,
    },
    metaInfo () {
      return {
        htmlAttrs: { lang: this?.lang || 'es' },
        meta: [

        ],
      }
    },
    data: () => ({
      eventsBus: new Vue(),
    }),
    computed: {
        ...sync('app', ['alert', 'scrollPosition']),
        ...get('app', ['alertTxt']),
        dataBlock: get('app/blocks.proyectos'),
        lang: get('app/langDefault'),
        items () { return sortby(filter(this.dataBlock.items, { hidden: false }), ['id']) },
    },
    watch: {
    },
    mounted () {
      window.onpopstate = (event) => {
        if (!this.$route?.hash) {
          this.eventsBus.$emit('closeItem')
        }
      }
      this.$refs.overlay.addEventListener('scroll', this.updateElementScroll)
    },
    beforeDestroy () {
      this.$refs.overlay.removeEventListener('scroll', this.updateElementScroll)
    },
    methods: {
      updateElementScroll (event) {
        const { srcElement } = event
        const element = srcElement.scrollingElement || srcElement
        const yPosition = element.scrollTop
        this.scrollPosition = yPosition
      },
      handleClickTo (position = 'top') {
        const element = this.$refs.overlay
        const coord = position === 'top' ? 0 : element.offsetHeight + 500
        element.scrollTo({
          top: coord,
          behavior: 'smooth', // Optional: Add smooth scrolling effect
        })
      },
    },

  }
</script>

<style lang="scss" >

</style>
