<template>
  <svg
    :width="width"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM11.6893 27.4907L10 24.5708H22.379L24.7064 28.6038H13.4924C13.1182 28.6035 12.7516 28.4992 12.4332 28.3027C12.1148 28.1062 11.8573 27.8251 11.6893 27.4907ZM29.6769 23.4965C29.8866 23.8228 29.9985 24.2023 29.9994 24.5902H30C30 25.0489 29.9119 25.2833 29.5994 25.8139L27.9976 28.5838L17.8479 11H21.3209C21.6878 10.9997 22.0479 11.0997 22.3622 11.289C22.6766 11.4783 22.9332 11.7499 23.1046 12.0743L29.6769 23.4965ZM16.1911 13.8518L20.7253 21.7065H11.6537L16.1911 13.8518Z"
      :fill="fill"
    />
  </svg>
</template>

<script>

  export default {
    name: 'LogoArtstation',
    props: {
      width: {
        type: Number,
        default: 40,
      },
      fill: {
        type: String,
        default: '#252422',
      },
    },
  }
</script>

<style>

</style>
