<template>
  <v-main>
    <VOverlay
      :value="appLoading"
      z-index="1"
    >
      <VProgressCircular
        indeterminate
        size="64"
      />
    </VOverlay>
    <router-view
      v-if="!appLoading"
      :key="$route.path"
    />
  </v-main>
</template>

<script>
  import { get } from 'vuex-pathify'
  export default {
    name: 'DefaultView',

    computed: {
      appLoading: get('app/loading'),
    },
  }
</script>
