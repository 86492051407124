<template>
  <svg
    :width="width"

    viewBox="0 0 47 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 0L0 25L25 50L30.2706 44.7294L14.2687 28.7274H46.2725V21.2725H14.2687L30.2706 5.27061L25 0Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
  export default {
    name: 'CrossIcon',
    props: {
      width: {
        type: Number,
        default: 50,
      },
      fill: {
        type: String,
        default: '#FFFFFF',
      },
    },
  }
</script>

<style>

</style>
