import request from '@/util/request'

export const genericRequest = (query) => {
  return request({
    url: query.url,
    method: query.method,
    params: query.params,
    headers: query.headers,
  })
}
