<template>
  <v-btn
    class="mx-2 fav-btn"
    fab
    dark
    small
    color="link"
    v-on="$listeners"
  >
    <v-img
      eager
      class=""
      contain
      position="center center"
      src="/images/2023/home/top.svg"
      alt="top"
      width="41px"
    />
  </v-btn>
</template>

  <script>
  export default {
    name: 'FavBtn',
    props: {

      height: {
        type: String,
        default: '1px',
      },
      color: {
        type: String,
        default: '#FFFFFF',
      },
    },
    methods: {

    },
  }
  </script>

  <style scoped lang="scss">
  .fav-btn{
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;
  }
  </style>
