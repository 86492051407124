// Imports
import Vue from 'vue'
import Router from 'vue-router'

import Layout from '@/layouts/default/Index'
import PageHome from '@/views/home/Index'
import Page404 from '@/views/error-page/404'
import Page401 from '@/views/error-page/401'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [

    {
      children: [
        {
          component: PageHome,
          path: '/',
        },
      ],
      component: Layout,
      path: '/',
    },
    {
      path: '/404',
      component: Page404,
    },
    {
      path: '/401',
      component: Page401,
    },
  ],
})

export default router
