import _ from 'lodash'

export function leadingSlash (str) {
  return str.startsWith('/') ? str : '/' + str
}

export function trailingSlash (str) {
  return str.endsWith('/') ? str : str + '/'
}

export function capitalize (word) {
  return word[0].toUpperCase() + word.slice(1).toLowerCase()
}

export const wait = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout))
}
export const months = (number) => {
  const months = {
    '01': 'Enero',
    '02': 'Febrero',
    '03': 'Marzo',
    '04': 'Abril',
    '05': 'Marzo',
    '06': 'Junio',
    '07': 'Julio',
    '08': 'Agosto',
    '09': 'Septiembre',
    10: 'Octubre',
    11: 'Noviembre',
    12: 'Diciembre',
  }
  if (number) { return months[number] }
  return months
}

export const shortDate = (date) => {
  if (!date) return null
  const [year, month, day] = date.split('-')

  return `${day}/${month}/${year}`
}

export const textLong = (date) => {
  if (!date) return null
  const [year, month, day] = date.split('-')

  return `${day} de ${months(month)} de ${year}`
}

export const dateHelpers = { shortDate, textLong, months }

export const fancyTimeFormat = (duration) => {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600)
    var mins = ~~((duration % 3600) / 60)
    var secs = ~~duration % 60

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = ''

    if (hrs > 0) {
        ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
    }

    ret += '' + mins + ':' + (secs < 10 ? '0' : '')
    ret += '' + secs
    return ret
}

export const subArray = (list, number) => {
  const array = []

  if (list?.length) {
    const arrayLength = list.length

    const parts = arrayLength / number

    let index = 0

    for (let i = 0; i < Math.ceil(parts); i++) {
        const arrayInt = []
        for (let int = 0; int < number; int++) {
            if (list[index]) {
                arrayInt.push(list[index])
                index += 1
            }
        }
        array.push(arrayInt)
    }
  }

  return array
}

export const sortby = (list, itemsSort) => {
  return _.sortBy(list, itemsSort)
}

export const filter = (list, itemsFilter) => {
  return _.filter(list, itemsFilter)
}

export const isOdd = (num) => { return num % 2 }
