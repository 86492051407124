<template>
  <svg
    :width="width"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM22.4999 15.6666H28.3331V14H22.4999V15.6666ZM25.5207 26.4999C28.0806 26.4999 29.4031 25.0807 29.7715 23.9999H27.1315C26.2107 25.069 23.4165 24.9849 23.3082 22.3091H29.9973C30.0089 21.5158 29.9831 20.9474 29.9181 20.5258C29.5756 18.3225 28.0073 16.8375 25.439 16.8375C22.8215 16.8375 20.884 18.5125 20.884 21.7707C20.884 25.059 22.959 26.4999 25.5207 26.4999ZM27.504 20.6666H23.3665C23.5424 19.4574 24.2182 18.8174 25.4398 18.8174C26.5573 18.8174 27.4165 19.3774 27.504 20.6666ZM10 26.4899H15.3883C21.0415 26.4899 20.9449 20.8224 18.0607 19.7724C20.4441 18.5541 20.3574 14.085 15.7941 14.0175H10V26.4899ZM15.4866 18.9999H12.5V16.5H15.2266C17.9082 16.5 17.5766 18.9999 15.4866 18.9999ZM12.5 21.4999H15.3258C17.6741 21.4999 17.8299 24.0132 15.2841 24.0132H12.5V21.4999Z"
      :fill="fill"
    />
  </svg>
</template>

<script>

  export default {
    name: 'LogoBehance',
    props: {
      width: {
        type: Number,
        default: 40,
      },
      fill: {
        type: String,
        default: '#252422',
      },
    },
  }
</script>

<style>

</style>
