<template>
  <v-footer
    id="default-footer"
    :color="$vuetify.theme.themes[$vuetify.theme.dark ? 'dark' : 'light'].background"
    class="px-0"
  >
    <v-container
      fluid
      class="mt-8 mt-md-16"
    >
      <v-row
        justify="center"
        align="center"
        class="mt-4"
      >
        <v-col
          cols="12"
          lg="11"
          xl="10"
        >
          <custom-divider :color="$vuetify.theme.themes[$vuetify.theme.dark ? 'dark' : 'light'].grey" />
        </v-col>
        <v-col
          cols="12"
          lg="11"
          xl="10"
        >
          <v-row
            justify="center"
            align="center"
            class="py-6"
          >
            <v-col
              cols="12"
              md="5"
            >
              <div
                text
                class="text--text font-weight-normal text-uppercase text-caption text-center text-md-start"
              >
                {{ dataBlock[`text${langDefault==='es'?'':'_en'}`] }}
              </div>
              <div
                text
                class="text--text text-subtitle-1 font-weight-bold text-center text-md-start"
              >
                {{ dataBlock.email }}
              </div>
              <div
                text
                class="text--text font-weight-normal text-uppercase text-caption mt-3 text-center text-md-start"
              >
                {{ dataBlock[`text2${langDefault==='es'?'':'_en'}`] }}
              </div>
              <div
                text
                class="text--text text-subtitle-1 font-weight-bold text-center text-md-start"
              >
                {{ dataBlock.email2 }}
              </div>
            </v-col>
            <v-col
              cols="12"
              md="5"
              class="d-flex justify-md-end justify-center align-center"
            >
              <div>
                <div class="d-flex align-center justify-center justify-md-start">
                  <a
                    v-for="(network,index) in dataBlock.social_media"
                    :key="index"
                    :href="network.url"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-none text-decoration-none pr-5"
                  >

                    <component
                      :is="`Logo${network.logo}`"
                      :width="25"
                    />
                  </a>
                </div>
                <div
                  v-for="(network,index) in dataBlock.phone"
                  :key="index"
                  class="mt-3"
                >
                  <a
                    :href="network.url"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-none text-decoration-none d-flex align-center justify-center justify-md-start"
                  >

                    <component
                      :is="`Logo${network.logo}`"
                      :width="21"
                    />
                    <span class="text--text text-number font-weight-bold pl-3 pr-2">
                      {{ network.region }}
                    </span>
                    <span class="text--text text-number font-weight-medium">
                      {{ network.number }}
                    </span>
                  </a>
                </div>
              </div>
            </v-col>
            <v-col
              cols="12"
              class="d-md-flex justify-space-between align-center mt-6 mt-md-10"
            >
              <div
                class="d-flex align-center justify-center justify-md-start"
              >
                <v-img
                  class="d-inline-block logo-black mr-6"
                  position="center center"
                  contain
                  eager
                  height="18px"
                  :src="app.logoUrl"
                  max-width="110px"
                />
                <span class="text--text text-caption font-weight-medium">

                  {{ dataBlock[`signature${langDefault==='es'?'':'_en'}`] }}
                </span>
              </div>
              <div class="d-flex align-center justify-center justify-md-end mt-5 mt-md-0">
                <a
                  :href="dataBlock.made_by.url"
                  target="_blank"
                  class="text-none text-decoration-none d-flex align-center"
                >
                  <span class="text--text text-caption font-weight-medium">
                    {{ dataBlock.made_by[`signature${langDefault==='es'?'':'_en'}`] }}
                  </span>
                  <v-img
                    class="d-inline-block"
                    position="center center"
                    contain
                    eager
                    height="18px"
                    :src="dataBlock.made_by.logo"
                    max-width="90px"
                  />
                </a>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <fav-btn
      v-if="scrollPosition>200"
      @click="handleClick"
    />
  </v-footer>
</template>

<script>
// Components
  import { get } from 'vuex-pathify'
  import anchors from '@/mixins/anchors'

  export default {
    name: 'DefaultFooter',
    components: { },
    mixins: [anchors],
    props: {
      isHome: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      app: get('app'),
      dataBlock: get('app/blocks.footer'),
      ...get('app', ['langDefault', 'scrollPosition']),
    },
    methods: {

      handleClick () {
        if (this.isHome) {
          this.toAnchor({ id: 'callReelAnchor' })
        } else {
          this.$emit('clickTopBtn')
        }
      },
    },
  }
</script>

<style lang="scss">

  #default-footer{
    flex:unset;
    z-index:2;
    .text-number{
      font-size: 1rem;
    }
    .v-toolbar__content{
        padding:0;
    }
    .make-question{
      line-height: 1.9rem;
      font-size: 1.9rem!important;
    }

  }
  .logo-black{
      -webkit-filter: invert(100%);
      filter: invert(100%);
    }
</style>
