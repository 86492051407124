<template>
  <svg
    :width="width"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30ZM10.1379 7.93325L8.19924 9.87011C7.65678 10.4162 7.40739 11.1899 7.53117 11.9417C7.94075 14.4028 9.24413 16.8348 11.2065 18.7953C13.1706 20.7577 15.6045 22.0629 18.0583 22.467C18.1839 22.4889 18.3113 22.4998 18.4442 22.4998C19.0741 22.4998 19.6875 22.2467 20.1317 21.8008L22.0668 19.8657C22.3453 19.5872 22.5 19.2158 22.5 18.8208C22.5002 18.6267 22.4621 18.4344 22.3877 18.2551C22.3134 18.0758 22.2043 17.9129 22.0668 17.7759L19.9788 15.6916C19.7003 15.4131 19.3289 15.2584 18.9339 15.2584C18.7401 15.2583 18.5482 15.2966 18.3692 15.3709C18.1901 15.4453 18.0276 15.5543 17.8908 15.6916L16.3654 17.2171C15.5782 16.8578 14.8623 16.3596 14.2519 15.7462C13.6401 15.1358 13.1436 14.4198 12.7866 13.6328L14.312 12.1073C14.5905 11.8288 14.7453 11.4575 14.7453 11.0624C14.7453 10.8686 14.7071 10.6767 14.6327 10.4977C14.5584 10.3187 14.4494 10.1561 14.312 10.0194L12.2259 7.93325C11.9474 7.65473 11.576 7.5 11.181 7.5C10.9872 7.49995 10.7952 7.5382 10.6162 7.61255C10.4372 7.6869 10.2747 7.79589 10.1379 7.93325Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
  export default {
    name: 'LogoPhone',
    props: {
      width: {
        type: Number,
        default: 30,
      },
      fill: {
        type: String,
        default: '#252422',
      },
    },
  }
</script>

<style>

</style>
