import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

const light = {
  background: '#FFFFFF',
  whiteLight: '#FFFFFF',
  text: '#252422',
  grey: '#CCC',
  link: '#43C3EC',
}

const dark = {
  background: '#252422',
  white: '#FFFFFF',
  whiteLight: '#252422',
  text: '#FFFFFF',
  grey: '#CCC',
  link: '#43C3EC',
}

export default new Vuetify({
  breakpoint: { mobileBreakpoint: 960 },
  icons: {
    values: { expand: 'mdi-menu-down' },
  },
  theme: {
    dark: false,
    themes: {
      dark,
      light,
    },
  },
})
