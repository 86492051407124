<template>
  <div>
    <default-bar
      v-if="!appLoading"
      is-home
    />

    <default-view />

    <default-footer
      v-if="!appLoading"
      id="callContactoAnchor"
      is-home
    />
  </div>
</template>

<script>
  import DefaultBar from './AppBar'
  import DefaultFooter from './Footer'
  import DefaultView from './View'
  import { get, call, sync } from 'vuex-pathify'
  export default {
    name: 'DefaultLayout',

    components: {
      DefaultBar, DefaultFooter, DefaultView,
    },
    computed: {
      appLoading: get('app/loading'),
      ...sync('app', ['scrollPosition']),
    },
    watch: {

    },
    created () {
      this.getData()
    },
    methods: {
      getData: call('app/getData'),
    },
  }
</script>
