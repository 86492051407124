<template>
  <v-container
    id="introduction"
    class="mt-8 mt-md-16"
    fluid
  >
    <v-row
      id="callIntroductionAnchor"
      justify="center"
      align="center"
    >
      <v-col
        cols="12"
        xl="11"
      >
        <v-row
          justify="center"
          align="center"
          class="mt-3"
        >
          <v-col
            cols="12"
            class="d-flex justify-center align-center py-1"
          >
            <v-img
              position="center center"
              contain
              height="38px"
              :lazy-src="smallLogoUrl"
              :src="smallLogoUrl"
            />
          </v-col>
          <v-col
            cols="11"
            md="8"
            lg="6"
            xl="5"
            class="d-flex justify-center align-center pb-0"
          >
            <span
              class="text-h5 text-md-h4 font-weight-negrita text--text text-center px-md-8 px-lg-10"
            >
              {{ introduction[`primary_text${lang === "es" ? "" : "_en"}`] }}
            </span>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          />
          <v-col
            cols="12"
            md="10"
            lg="8"
            xl="7"
            class="d-flex justify-center align-center"
          >
            <span
              :class="{'introduction-text':$vuetify.breakpoint.mdAndUp}"
              class="text-subtitle-1 font-weight-regular mt-5 text--text text-center"
            >
              {{ introduction[`secondary_text${lang === "es" ? "" : "_en"}`] }}
            </span>
          </v-col>
          <v-col
            cols="12"
          />
          <v-col
            cols="12"
            md="10"
          >
            <v-row justify="center">
              <v-col
                v-for="(item, index) in introduction.items"
                :key="index"
                cols="12"
                sm="8"
                md="7"
                lg="4"
                class="mt-4 mt-md-10"
              >
                <div
                  class="items-card text-center"
                  :class="{isMobile:$vuetify.breakpoint.smAndDown}"
                >
                  <v-chip
                    :class="{'introduction-title':$vuetify.breakpoint.mdAndUp}"
                    class="text-subtitle-1 font-weight-negrita white--text px-4 px-xl-6 py-3 mt-n4 text-uppercase"
                    color="text"
                  >
                    {{ item[`title${lang === "es" ? "" : "_en"}`] }}
                  </v-chip>
                  <div class="mt-2">
                    <div
                      v-for="(itemInner, keyInner) in item.bullets"
                      :key="`inner${keyInner}${index}`"
                      :class="{'introduction-item':$vuetify.breakpoint.mdAndUp}"
                      class="text-subtitle-1 font-weight-medium"
                    >
                      {{ itemInner[`text${lang === "es" ? "" : "_en"}`] }}
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  export default {
    computed: {
      lang: get('app/langDefault'),
      introduction: get('app/blocks.introduction'),
      smallLogoUrl: get('app/smallLogoUrl'),
    },
  }
</script>

<style lang="scss">

  #introduction {
    .introduction-title{
      &.text-subtitle-1{
        font-size:1.1rem!important;
        line-height: 1.1rem!important;
      }

    }
    .introduction-item{
      &:not(:last-child) {
        padding-bottom: 0.55rem !important;
      }
      &:first-child{
        padding-top: 1rem !important;
      }
      &:last-child{
        padding-bottom: 1.5rem !important;
      }

      &.text-subtitle-1{
          font-size:1.2rem!important;
          line-height: 1.5rem!important;

      }

    }
    .introduction-text{
      &.text-subtitle-1{
          font-size:1.2rem!important;
          line-height: 1.8rem!important;
      }
    }
    z-index: 5;
    position: relative;
    .items-card{
      border-radius: 10px;
      border: 1px solid #EDEDED;
      background: #FFF;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
      min-height: 160px;
      &.isMobile{
        min-height: unset;
        padding: 12px 0;
      }
    }
    .title-we {
      font-size: 7rem !important;
      line-height: 5.6rem !important;
    }
    .title-we-xl {
      font-size: 8rem !important;
      line-height: 6.5rem !important;
    }
    .subtitle-we-second-line {
      line-height: 1.4rem !important;
    }
    .subtitle-we-second {
      height: 80px;
      font-size: 1.1rem !important;
  }
}
</style>
