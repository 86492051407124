// Pathify
import { make } from 'vuex-pathify'
import { genericRequest } from '@/api/app'

import { setToken, getToken } from '@/util/auth'

// Data
const state = {
  drawer: null,
  mini: false,
  loading: true,
  alert: false,
  alertTxt: { timeout: 500, type: 'success', txt: 'Cambios guardados' },
  logoUrl: getToken('six_studio_logoUrl'),
  smallLogoUrl: getToken('six_studio_smallLogoUrl'),
  baseUrl: getToken('six_studio_baseUrl'),
  meta: { },
  blocks: {
    nav: { items: getToken('six_studio_nav_items') },
    hero: { },
    nosotros: { title: '', items: [] },
    clientes: { title: '', items: [] },
    proyectos: { title: '', items: [] },
    introduction: { items: [] },
    footer: { social_media: [] },
  },
  langDefault: getToken('six_studio_lang') || 'es',
  lang: {
    default: 'es',
    logoEn: '/images/logos/en.png',
    logoEs: '/images/logos/es.png',
  },
  scrollPosition: 0,
  proyectOpen: null,
}

const mutations = make.mutations(state)

const actions = {
  async getData ({ commit, state }) {
    const {
      base_url: baseUrl,
      logo_url: logoUrl,
      small_logo_url: smallLogoUrl,
      blocks,
      lang,
    } = await genericRequest({ url: 'getDataPageHome', method: 'GET' })
    commit('SET_BLOCKS', blocks)

    commit('SET_LOADING', false)

    if (blocks?.nav?.items) {
      setToken(blocks.nav.items, 'six_studio_nav_items')
    }

    commit('SET_BASE_URL', baseUrl)
    setToken(baseUrl, 'six_studio_baseUrl')

    commit('SET_LOGO_URL', logoUrl)
    setToken(logoUrl, 'six_studio_logoUrl')

    commit('SET_SMALL_LOGO_URL', smallLogoUrl)
    setToken(smallLogoUrl, 'six_studio_smallLogoUrl')

    commit('SET_LANG', { ...state.lang, default: lang.default })
    if (!getToken('six_studio_lang')) {
      commit('SET_LANG_DEFAULT', lang.default)
    }
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
