<template>
  <div
    :style="`--custom-size: ${width}; --custom-color: #FFFFFF;`"
  >
    <template
      v-if="$slots.default"
    >
      <slot
        name="default"
      />
    </template>
  </div>
</template>

<script>
  export default {
    name: 'CustomCircle',
    props: {

      width: {
        type: String,
        default: '',
      },
    },
  }
</script>

<style scoped lang="scss">
    div{
        background: var(--custom-color);
        width: var(--custom-size);
        height: var(--custom-size);
        border-radius:var(--custom-size);
    }
</style>
