
<template>
  <v-container
    class="mt-8 mt-md-16"
    fluid
  >
    <v-row
      id="callProyectosAnchor"
      justify="center"
      class="mt-4 mt-md-16"
    >
      <v-col
        cols="12"
        lg="11"
        xl="10"
      >
        <div
          class="masonry menu"
          :class="{isMobile:$vuetify.breakpoint.smAndDown}"
        >
          <template
            v-for="(data,index) in itemsNested"
          >
            <div
              v-if="data.hasChildren"
              :key="`children_${index}`"
              :class="{isMobile:$vuetify.breakpoint.smAndDown,[data.area]:true}"
            >
              <template
                v-for="(dataInner,indexInner) in data.childrens"
              >
                <div
                  v-if="dataInner.hasChildren"
                  :key="`${index}_${indexInner}`"
                  :class="{isMobile:$vuetify.breakpoint.smAndDown,[dataInner.area]:true}"
                >
                  <template
                    v-for="(_dataInner,_indexInner) in dataInner.childrens"
                  >
                    <a
                      :key="`${index}_${indexInner}_${_indexInner}`"
                      :ref="`#proyecto-${_dataInner.id}`"
                      :class="{isMobile:$vuetify.breakpoint.smAndDown,[_dataInner.area]:true}"
                      class="card-item p-relative grid__item  text-none text-decoration-none"
                      :href="`#proyecto-${_dataInner.id}`"
                      :data-id="_dataInner.id"
                      @click="openWork(_dataInner.id)"
                    >
                      <v-img
                        cover
                        position="center center"
                        height="100%"
                        eager
                        :src="_dataInner.portadaWebp || _dataInner.portada"
                        :alt="_dataInner.name"
                        class="card-img box__img"
                      />
                      <div class="work-title d-flex justify-start align-center">
                        <span class="white--text text-subtitle-1 text-md-h6 font-weight-negrita">
                          {{ _dataInner.name }}
                        </span>
                        <v-img
                          class="arrow-right ml-3"
                          contain
                          eager
                          position="center center"
                          src="/images/2023/home/arrow_right_alt.svg"
                          alt="arrow right"
                          width="30px"
                        />
                      </div>
                    </a>
                  </template>
                </div>
                <a
                  v-else
                  :key="`doble_${index}_${indexInner}`"
                  :ref="`#proyecto-${dataInner.id}`"
                  :class="{isMobile:$vuetify.breakpoint.smAndDown,[dataInner.area]:true}"
                  class="card-item p-relative grid__item text-none text-decoration-none"
                  :href="`#proyecto-${dataInner.id}`"
                  :data-id="dataInner.id"
                  @click="openWork(dataInner.id)"
                >
                  <v-img
                    cover
                    position="center center"
                    :src="dataInner.portadaWebp || dataInner.portada"
                    :alt="dataInner.name"
                    height="100%"
                    eager
                    class="card-img box__img"
                  />
                  <div class="work-title d-flex justify-start align-center">
                    <span class="white--text text-subtitle-1 text-md-h6 font-weight-negrita">
                      {{ dataInner.name }}
                    </span>
                    <v-img
                      class="arrow-right ml-3"
                      contain
                      eager
                      position="center center"
                      src="/images/2023/home/arrow_right_alt.svg"
                      alt="arrow right"
                      width="30px"
                    />
                  </div>
                </a>
              </template>
            </div>
            <a
              v-else
              :key="`simple_${index}`"
              :ref="`#proyecto-${data.id}`"
              :class="{isMobile:$vuetify.breakpoint.smAndDown,[data.area]:true}"
              class="card-item p-relative grid__item text-none text-decoration-none"
              :href="`#proyecto-${data.id}`"
              :data-id="data.id"
              @click="openWork(data.id)"
            >
              <v-img
                cover
                position="center center"
                :src="data.portadaWebp || data.portada"
                height="100%"
                eager
                :alt="data.name"
                class="card-img box__img"
              />
              <div class="work-title d-flex justify-start align-center">
                <span class="white--text text-subtitle-1 text-md-h6 font-weight-negrita">
                  {{ data.name }}
                </span>
                <v-img
                  class="arrow-right ml-3"
                  contain
                  eager
                  position="center center"
                  src="/images/2023/home/arrow_right_alt.svg"
                  alt="arrow right"
                  width="30px"
                />
              </div>
            </a>
          </template>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { sortby, filter } from '@/util/helpers'
  import { get, sync } from 'vuex-pathify'
  import Grid from '@/plugins/layoutMotion/grid'

  export default {

    data: () => ({

    }),

    computed: {
      ...sync('app', ['scrollPosition', 'proyectOpen']),
      dataBlock: get('app/blocks.proyectos'),
      lang: get('app/langDefault'),
      items () { return sortby(filter(this.dataBlock.items, { hidden: false }), ['id']) },
      itemsNested () {
        if (this.items?.length) {
          const nestedArray = this.generateNestedStructure(this.items)
          return nestedArray
        }

        return []
      },
    },
    mounted () {
      this.initLayoutMotion()
      this.checkHash()
    },
    methods: {
      generateNestedStructure (inputArray) {
        const parentMap = {}
        const outputArray = []

        inputArray.forEach(item => {
          const areas = item.area.split('-')
          let currentParent = null
          let currentPath = ''

          areas.forEach((area, index) => {
            const areaKey = index === 0 ? area : `${currentPath}-${area}`

            if (!parentMap[areaKey]) {
              const newItem = { area: areaKey }
              parentMap[areaKey] = newItem
              if (currentParent) {
                parentMap[currentParent].hasChildren = true
                if (!parentMap[currentParent]?.childrens?.length) {
                  parentMap[currentParent].childrens = []
                }
                parentMap[currentParent].childrens.push(newItem)
              } else {
                outputArray.push(newItem)
              }
            }

            if (index === areas.length - 1) {
              parentMap[areaKey] = Object.assign(parentMap[areaKey], item)
            }

            currentParent = areaKey
            currentPath = areaKey
          })
        })

        return outputArray
      },
      initLayoutMotion () {
        const menuEl = document.querySelector('#callProyectosAnchor .menu')
        // eslint-disable-next-line no-new
        new Grid(menuEl)
      },
      checkHash () {
        const hash = this.$route.hash
        if (hash && this.$refs[hash]?.[0]) {
          this.$refs[hash][0].click()
        }
      },
      openWork (id) {
        this.scrollPosition = 0
        this.proyectOpen = id
      },
    },

  }
</script>
<style scoped lang="scss">
  #callProyectosAnchor{

    .work-title{
      position: absolute;
      left:25px;
      bottom:20px;
    }
    .masonry {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: 400px 500px 500px;
    gap: 18px 18px;
    grid-auto-flow: row;
    grid-template-areas:
      "first first first first first first first first first"
      "second second second second third third third third third"
      "fourth fourth fourth fifth fifth fifth fifth fifth fifth";

      &.isMobile{
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas:
          "first"
          "second"
          "third"
          "fourth"
          "fifth";
      }
  }

  .first { grid-area: first; }

  .second { grid-area: second; }

  .third {  display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 241px 241px;
    gap: 18px 18px;
    grid-auto-flow: row;
    grid-template-areas:
      "third-1"
      "third-2";
    grid-area: third;
    &.isMobile{
      grid-template-rows: auto;
    }
  }

  .third-1 { grid-area: third-1; }

  .third-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    gap: 18px 18px;
    grid-auto-flow: row;
    grid-template-areas:
      "third-2-1 third-2-2";
    grid-area: third-2;
    &.isMobile{
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      grid-template-areas:
      "third-2-1"
      "third-2-2";
    }
  }

  .third-2-1 { grid-area: third-2-1; }

  .third-2-2 { grid-area: third-2-2; }

  .fourth { grid-area: fourth; }

  .fifth {  display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 241px 241px;
    gap: 18px 18px;
    grid-auto-flow: row;
    grid-template-areas:
      "fifth-1"
      "fifth-2";
    grid-area: fifth;
    &.isMobile{
      grid-template-rows: auto;
    }
  }

  .fifth-1 { grid-area: fifth-1; }

  .fifth-2 { grid-area: fifth-2; }

  @media all and (-ms-high-contrast:none) {
    .container {
      display: -ms-grid;
      -ms-grid-columns: repeat(7, 1fr);
      -ms-grid-rows: repeat(3, 1fr);
    }

    .first {
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 7;
    }

    .second {
      -ms-grid-row: 2;
      -ms-grid-row-span: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
    }

    .third {
      display: -ms-grid;
      -ms-grid-columns: 1fr;
      -ms-grid-rows: repeat(2, 1fr);
      -ms-grid-row: 2;
      -ms-grid-row-span: 1;
      -ms-grid-column: 4;
      -ms-grid-column-span: 4;
    }

    .third-1 {
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
    }

    .third-2 {
      display: -ms-grid;
      -ms-grid-columns: repeat(2, 1fr);
      -ms-grid-rows: 1fr;
      -ms-grid-row: 2;
      -ms-grid-row-span: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
    }

    .third-2-1 {
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
    }

    .third-2-2 {
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      -ms-grid-column: 2;
      -ms-grid-column-span: 1;
    }

    .fourth {
      -ms-grid-row: 3;
      -ms-grid-row-span: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 2;
    }

    .fifth {
      display: -ms-grid;
      -ms-grid-columns: 1fr;
      -ms-grid-rows: repeat(2, 1fr);
      -ms-grid-row: 3;
      -ms-grid-row-span: 1;
      -ms-grid-column: 3;
      -ms-grid-column-span: 5;
    }

    .fifth-1 {
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
    }

    .fifth-2 {
      -ms-grid-row: 2;
      -ms-grid-row-span: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
    }
  }

  .card-img {
      width: 100%;
      transition: transform 0.7s, filter 0.7s;
      -webkit-transition: transform 0.7s, filter 0.7s;
  }

  .card-item {
    /* grid-area: masonry; */
    width: 100%;
    overflow: hidden;
    cursor:pointer;
    .arrow-right{
        opacity: 0;
        transition: opacity 0.7s;
        -webkit-transition: opacity 0.7s;
    }
    &:hover{
      .arrow-right{
        opacity: 1;
      }
      .card-img{
        &.box__img{
          transform: scale(1.1) !important;
          filter: brightness(45%) !important;
        }
      }
    }
  }

  }

</style>
