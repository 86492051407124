<template>
  <svg
    :width="width"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30ZM20.7328 10.9307C21.3558 10.856 21.9484 10.6909 22.5 10.4464L22.4987 10.4484C22.0857 11.066 21.5661 11.6051 20.9641 12.0405C20.97 12.1726 20.9733 12.3061 20.9733 12.4382C20.9733 16.505 17.8783 21.194 12.2168 21.194C10.5444 21.1959 8.90677 20.7159 7.5 19.8114C7.74382 19.84 7.98911 19.8544 8.23461 19.8543C9.62051 19.8563 10.9668 19.3921 12.0569 18.5364C11.4148 18.5246 10.7923 18.3123 10.2768 17.9292C9.76129 17.5461 9.37847 17.0114 9.18193 16.3999C9.64327 16.4878 10.1185 16.4698 10.5719 16.3471C9.8753 16.2063 9.24888 15.8289 8.7989 15.2789C8.34892 14.7288 8.1031 14.04 8.10314 13.3294V13.2897C8.53048 13.5273 9.00837 13.6593 9.49705 13.6749C9.07568 13.3935 8.73029 13.0124 8.49151 12.5655C8.25273 12.1186 8.12795 11.6197 8.12825 11.113C8.12825 10.5502 8.28019 10.0217 8.54444 9.56583C9.31726 10.5166 10.2813 11.2942 11.3741 11.8482C12.4669 12.4022 13.664 12.7203 14.8877 12.7817C14.7328 12.1216 14.8002 11.4288 15.0794 10.8109C15.3586 10.193 15.834 9.68455 16.4317 9.36451C17.0295 9.04447 17.7162 8.93073 18.3852 9.04095C19.0543 9.15118 19.6682 9.4792 20.1317 9.97409C20.8209 9.8388 21.4819 9.5863 22.0858 9.2276C21.8565 9.94071 21.3756 10.5461 20.7328 10.9307Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
  export default {
    name: 'LogoTwitter',
    props: {
      width: {
        type: Number,
        default: 30,
      },
      fill: {
        type: String,
        default: '#252422',
      },
    },
  }
</script>

<style>
</style>
