import Cookies from 'js-cookie'

const TokenKey = 'six_studio_token_session'

export function getToken (key = TokenKey) {
  const token = Cookies.get(key)
  return token
}

export function setToken (token, key = TokenKey) {
  return Cookies.set(key, token)
}

export function removeToken (key = TokenKey) {
  return Cookies.remove(key)
}
