<template>
  <v-row
    id="work"
    justify="center"
  >
    <v-col
      cols="12"
      sm="10"
      md="11"
      lg="5"
      class="pt-1"
    >
      <div
        class="text-h5 font-weight-bold text--text"
        :class="{'md-text':$vuetify.breakpoint.mdAndUp}"
      >
        {{ item.full_name }}
      </div>
      <div
        class="text-subtitle-1 font-weight-medium text--text py-2"
        :class="{'md-text':$vuetify.breakpoint.mdAndUp}"
      >
        {{ item[`info${lang === "es" ? "" : "_en"}`] }}
      </div>
    </v-col>
    <v-col
      cols="12"
      sm="10"
      md="11"
      lg="5"
      class="pt-0"
    >
      <div
        class="text-subtitle-1 font-weight-medium text--text"
        :class="{'md-text':$vuetify.breakpoint.mdAndUp}"
      >
        {{ item[`description${lang === "es" ? "" : "_en"}`] }}
      </div>
    </v-col>
    <template v-if="portada2 && portada2.length">
      <v-col
        v-for="(portada,keyPortada) in portada2"
        :key="keyPortada"
        cols="12"
        sm="10"
        md="11"
        lg="10"
      >
        <v-img
          cover
          position="center center"
          width="100%"
          eager
          :src="portada"
          :alt="item.full_name"
          class=""
        />
      </v-col>
      <v-col
        cols="12"
        sm="10"
        md="11"
        lg="10"
      >
        <custom-divider :color="$vuetify.theme.themes[$vuetify.theme.dark ? 'dark' : 'light'].grey" />
      </v-col>
    </template>

    <v-col
      v-if="content.items.length"
      cols="12"
      sm="10"
      md="11"
      lg="10"
      class="my-2 my-lg-9"
    >
      <v-row
        :justify="content.justify"
        :align="content.align"
      >
        <v-col
          v-for="(_content,keyContent) in content.items"
          :key="`keyContent_${keyContent}`"
          cols="12"
          :md="_content.md || 6"
          :lg="_content.lg || 4"
          :class="{'my-2':_content.type==='img' || _content.type==='video'}"
        >
          <iframe
            v-if="_content.type==='google_video'"
            width="100%"
            :height="_content.height"
            :src="`https://www.youtube.com/embed/${_content[lang === 'es' ? 'google_key': 'google_key_en']}`"
            :title="_content.title"
            frameborder="0"
            allow=""
            allowfullscreen
          />
          <v-img
            v-if="_content.type==='img'"
            cover
            position="center center"
            width="100%"
            eager
            :src="$vuetify.breakpoint.smAndDown && _content['src-min'] ?_content['src-min']:_content.src"
            :alt="_content.title"
            class=""
          />
          <video
            v-if="_content.type==='video'"
            width="100%"
            :poster="_content.poster"
            :src="_content.src"
            :autoplay="$vuetify.breakpoint.mdAndUp && _content.autoplay"
            muted
            loop
            controls
            playsinline=""
            preload="none"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col
      v-if="item && item.google_channel"
      cols="12"
      class="d-flex justify-center"
    >
      <a
        class="d-flex justify-center align-center text-decoration-none"
        :href="`https://www.youtube.com/${item.google_channel.id}`"
        target="blank"
      >
        <span class="text--text text-subtitle-1 font-weight-negrita">
          Encontrá todos los capitulos en nuestro canal de youtube
        </span>
        <v-img
          class="arrow-right ml-2"
          contain
          position="center center"
          src="/images/2023/home/arrow_right_alt_black.svg"
          alt="arrow right"
          width="20px"
        />
      </a>
    </v-col>
  </v-row>
</template>

  <script>
  import { get } from 'vuex-pathify'
  export default {
    props: {
      item: {
        type: [Object],
        default: () => {},
      },
    },
    data: () => ({
    }),
    computed: {
      lang: get('app/langDefault'),
      portada2 () {
        return this.item?.portada2?.length ? this.item.portada2 : []
      },
      content () {
        return {
          justify: this.item?.content?.justify || 'start',
          align: this.item?.content?.align || 'start',
          items: this.item?.content?.items?.length ? this.item.content.items : [],
        }
      },
    },

    methods: {

    },
  }
  </script>

  <style lang="scss">
  #work{
    .md-text{
      &.text-h5{
          font-size:2rem!important;
          line-height: 2.5rem!important;

      }
      &.text-subtitle-1{
          font-size:1.2rem!important;
          line-height: 1.9rem!important;

      }
    }
  }

  </style>
