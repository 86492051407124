<template>
  <VContainer
    fluid
    tag="section"
    class="px-0"
  >
    <v-row>
      <v-col
        class="pa-0 p-relative"
      >
        <v-img
          eager
          cover
          position="center center"
          :height="$vuetify.breakpoint.smAndDown?'300px':'68vh'"
          :src="item.portadaWebp || item.portada"
          :alt="item.full_name"
          class=""
        />
      </v-col>
    </v-row>
  </vcontainer>
</template>

  <script>
  export default {
    props: {
      item: {
        type: [Object],
        default: () => {},
      },
    },
    data: () => ({
    }),
    computed: {
    },
    watch: {

    },
    methods: {

    },
  }
  </script>

  <style lang="scss">

  </style>
