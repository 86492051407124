<template>
  <div
    :style="`--custom-size: ${height}; --custom-color: ${color};`"
  />
</template>

<script>
  export default {
    name: 'CustomDivider',
    props: {

      height: {
        type: String,
        default: '1px',
      },
      color: {
        type: String,
        default: '#FFFFFF',
      },
    },
  }
</script>

<style scoped lang="scss">
    div{
      height: 0px;
      border-top: var(--custom-size) solid var(--custom-color);
    }
</style>
