<template>
  <VContainer
    id="hero"
    fluid
    tag="section"
    class="px-0"
  >
    <v-row id="callReelAnchor">
      <v-col
        id="hero-container"
        class="pa-0 p-relative"
        :class="{
          isPlaying
        }"
      >
        <video
          :poster="`${hero.background_video.poster}`"
          class="intro-loop-video"
          autoplay
          muted
          loop
        >
          <source
            :src="`${hero.background_video.video}`"
            type="video/mp4"
          >
        </video>
        <custom-circle
          v-if="hero.reel"
          id="custom-circle-hero"
          class="custom-animation d-flex justify-center align-center"
          width="80px"
        >
          <v-btn
            class="btn-play px-0"
            text
            @click="handleVideoClick"
          >
            <span class="text-subtitle-1 text--text font-weight-negrita text-center">PLAY<br>REEL</span>
          </v-btn>
        </custom-circle>
        <div
          class="intro-home-video-wrapper"
          @click="handleVideoClick"
        >
          <video
            v-show="isPlaying"
            ref="introHomeVideo"
            class="intro-home-video"
            :poster="`${hero.background_video.poster}`"
            :src="`${hero.background_video.video}`"
            playsinline=""
            @keydown.esc="handleVideoEsc"
            @click="handleVideoClick"
            @ended="handleVideoEsc"
          />
        </div>
      </v-col>
    </v-row>
  </vcontainer>
</template>

<script>
  import { get } from 'vuex-pathify'
  export default {
    filters: {
    },
    data: () => ({
      isPlaying: false,
    }),
    computed: {
      hero: get('app/blocks.hero'),
    },
    watch: {

    },
    methods: {
      handleVideoEsc () {
        if (this.isPlaying) {
          this.toggleIntroHomeVideo()
        }
      },
      handleVideoClick (event) {
        event.preventDefault()
        event.stopPropagation()
        const videoElement = this.$refs.introHomeVideo
        if (document.fullscreenElement !== videoElement) {
          this.toggleIntroHomeVideo()
        }
      },
      initVideo () {
        if (this.$refs?.introHomeVideo) {
          const document = this.$refs.introHomeVideo
          document.play()
          document.controls = this.$vuetify.breakpoint.mdAndUp
          document.focus()
        }
      },
      endvideo () {
        const document = this.$refs.introHomeVideo
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        }
        document.load()
        document.pause()
        document.currentTime = 0
      },
      toggleIntroHomeVideo () {
        document.body.style.position = 'relative'
        document.documentElement.style.overflowY = 'scroll'
        this.isPlaying = !this.isPlaying
        if (this.isPlaying) {
          document.documentElement.style.overflowY = 'hidden'
          document.body.style.position = 'fixed'
          this.initVideo()
        } else {
          this.endvideo()
        }
      },

    },
  }
</script>

<style lang="scss">
#hero{
  position:relative;
  background:white;
  .intro-loop-video{
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center top;
      object-position: center top;
      width: 100%;
      height:100vh;
      position: absolute;
      left: 0;
      bottom: 0;
      &:focus { outline:none; }

    }
  .btn-play{
    text-align: center;
    width: 100px;
    height: 100px;
    span{
      line-height: 1.1rem;
    }
  }
  #custom-circle-hero{
    position: absolute;
    bottom:20%;
    left:50%;
    transform: translate(-50%, 0%);
  }

  .custom-animation-hidden{
    -webkit-transition: opacity 0.2s cubic-bezier(0.28, 0, 0.49, 1) 0.1s ;
    -moz-transition: opacity 0.2s cubic-bezier(0.28, 0, 0.49, 1) 0.1s ;
    -o-transition: opacity 0.2s cubic-bezier(0.28, 0, 0.49, 1) 0.1s ;
    transition: opacity 0.2s cubic-bezier(0.28, 0, 0.49, 1) 0.1s ;
  }
  .custom-animation{
    -webkit-transition: opacity 0.2s cubic-bezier(0.28, 0, 0.49, 1) 0.35s ;
    -moz-transition: opacity 0.2s cubic-bezier(0.28, 0, 0.49, 1) 0.35s ;
    -o-transition: opacity 0.2s cubic-bezier(0.28, 0, 0.49, 1) 0.35s ;
    transition: opacity 0.2s cubic-bezier(0.28, 0, 0.49, 1) 0.35s ;
  }
  #hero-container{
    height:100vh;
    overflow: hidden;
    .intro-home-video-wrapper{
      width: 100%;
      height: 100vh;
      background: black;
      position: fixed;
      top:0;
      left:0;
      display: flex;
      visibility: hidden;
      align-content: center;
      justify-content: center;
      z-index:9;
    }
    .intro-home-video {
        margin: auto;
        max-width: 100%;
        position: absolute;
        max-height: 100vh;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index:9;
        visibility: hidden;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: center center;
        object-position: center center;
    }

    &.isPlaying{
      .intro-home-video-wrapper{
        visibility: visible;
      }
      .intro-home-video {
        visibility: visible;
      }
    }
  }
}
</style>
