<template>
  <div>
    <hero />
    <introduction />
    <proyectos />
  </div>
</template>

<script>
  import { sync } from 'vuex-pathify'
  import Hero from './blocks/Hero.vue'
  import Introduction from './blocks/Introduction.vue'
  import Proyectos from './blocks/Proyectos.vue'

  export default {
    name: 'DashboardView',
    filters: {

    },
    components: {
      Hero,
      Introduction,
      Proyectos,
    },
    data: () => ({
    }),

    computed: {
      appLoading: sync('app/loading'),
    },

    mounted () {
    },
    created () {
    },
    methods: {

    },

  }
</script>
